import { ContactUsPageDTO } from "entities/ContactUsPageDTO";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import ContactHeader from "./ContactHeader";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getContactUs } from "store/slices/contactUsSlice";
import { BaseProp } from "entities/BaseProp";
import Divider from "components/Divider";
import HelmetComponent from "components/HelmetComponent/HelmetComponent";
import HeadingText1 from "components/HeadingText/HeadingText1";

interface ContactUsProp extends BaseProp {}

const ContactUs: FC<ContactUsProp> = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const response = useAppSelector((state) => state.contactUs.data);
  const isAPILoad = useAppSelector(
    (state) => state.contactUs.isLoading
  ) as boolean;

  useEffect(() => {
    if (!response) {
      dispatch(getContactUs());
    }
  }, [dispatch, response]);

  const primaryLocation = response?.data
    .filter((a) => a.isPrimaryLocation)
    .sort((a, b) => a.locationName.localeCompare(b.locationName) || 0);

  const secondaryLoc = response?.data
    .filter((a) => !a.isPrimaryLocation)
    .sort((a, b) => a.locationName.localeCompare(b.locationName) || 0);

  const renderLocationCard = (item: ContactUsPageDTO, index: number) => {
    return (
      <div key={index} className="nc-LocationCard group">
        <div
          className="relative aspect-w-5 aspect-h-3 rounded-3xl overflow-hidden group-hover:-translate-y-2 transition-all duration-400 
      shadow group-hover:shadow-lg group-hover:shadow-slate-500"
        >
          <Link to={`/contact-us/${item.systemName}`} />
          <NcImage
            className="object-fit w-full h-full transform group-hover:scale-105 transition-transform duration-300"
            src={item.logoUrl}
          />
          {/* <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div> */}
          {/* <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
            <h2 className="block text-lg font-semibold text-black ">
              <span className="line-clamp-2">{item.locationName}</span>
            </h2>
          </div> */}
          <Link to={`/contact-us/${item.systemName}`} />
        </div>
        <div className="px-2.5 pt-5 pb-2.5">
          <Link
            to={`/contact-us/${item.systemName}`}
          >
            <h2
              className={`nc-ProductCard__title text-base sm:text-lg font-semibold transition-colors group-hover:text-csccolor`}
            >
              {item.locationName}
            </h2>
          </Link>
        </div>
      </div>
    );
  };

  const renderBranches = (titleName: string, array: ContactUsPageDTO[]) => {
    return (
      <>
        {/* HEADING */}
        <div className="max-w-screen-sm">
          <HeadingText1 className="">{titleName}</HeadingText1>
        </div>
        <Divider />
        <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
          {array.map((v, i) => renderLocationCard(v, i))}
        </div>
      </>
    );
  };

  return (
    <div className={`nc-ContactUs ${className}`} data-nc-id="ContactUs">
      {/* Title & meta data */}
      <HelmetComponent
        title=" Contact CSC LED | Get in Touch with Our LED Lighting Experts"
        metaDescription="Have questions about our LED lighting solutions? Contact CSC LED for expert guidance on sustainable lighting products, custom solutions, and support. We're here to help!"
        metaKeywords="CSC LED contact, LED lighting support, LED lighting inquiries, Lighting solutions assistance, CSC LED customer service, Contact LED lighting expects, Sustainable lighting solutions"
      />
      <ContactHeader
        bodyMsg={`Select a CSC LED branch or partnered sales agency below to get in touch today.`}
      />
      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          {primaryLocation &&
            renderBranches("CSC LED Branches", primaryLocation)}
          {secondaryLoc &&
            renderBranches("Our Partnered Sales Agencies", secondaryLoc)}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
